body *,
body ::before,
body ::after {
  box-sizing: border-box;
}
html body {
  color: black;
  font-size: 14px;
  font-family: Barlow, sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html:first-child,
html body,
body #root {
  height: 100%;
  scroll-behavior: smooth;
}
body html > .ant-layout,
html body > .ant-layout,
body #root > .ant-layout {
  height: 100%;
}
body #page-container {
  position: relative;
  height: 100%;
  padding-left: 16px;
  overflow: auto;
}
@media print {
  body aside {
    display: none;
  }
  body #page-container {
    position: absolute !important;
    height: auto !important;
  }
}
body b,
body strong {
  font-weight: 700;
}
body p {
  font-size: 14px;
}
body img,
body embed,
body object,
body video {
  max-width: 100%;
}
body video {
  display: block;
  outline: none;
}
body a {
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
body .colorWeak {
  filter: invert(80%);
}
body .ome_player_container {
  --op-accent-color: white;
}
body .ome_player_container .op-message-container {
  display: none;
}
body .op-spinner-container {
  display: none !important;
}
body #video-ctn::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: #212529;
  border: 1px solid #212529;
}
body #video-ctn::-webkit-scrollbar-corner {
  background: #212529;
}
body #video-ctn::-webkit-scrollbar-track {
  background: #212529;
  border-radius: 8px;
  box-shadow: none !important;
  cursor: pointer;
}
body #video-ctn::-webkit-scrollbar-thumb {
  background: #6C757D;
  border-radius: 8px;
}
body .no-input-datepicker {
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
}
body .no-input-datepicker > .ant-picker-input > input {
  display: none;
}
body .no-input-datepicker .ant-picker-suffix {
  margin-left: 0;
  color: black;
  font-size: 14px;
}
body .upload-full-width {
  display: block;
  width: 100%;
}
body .upload-full-width .ant-upload-select {
  width: 100% !important;
}
body .ant-legacy-form-item {
  margin-bottom: 8px;
}
body .ant-legacy-form-extra {
  font-size: 10px;
}
body .ant-legacy-form-vertical .ant-legacy-form-item {
  padding-bottom: 0;
}
body .ant-cascader-picker-label {
  width: calc(100% - 10px);
}
body form .ant-select {
  width: 100%;
}
body .df-popover .ant-popover-content .ant-popover-inner {
  background-color: black;
  border-radius: 8px;
}
body .df-popover .ant-popover-content .ant-popover-inner .ant-popover-inner-content {
  padding: 2px;
}
body .df-popover .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
  border-top-color: #100f1e !important;
  border-left-color: #100f1e !important;
}
body .ant-popover > .ant-popover-arrow::before {
  width: 16px;
  height: 8px;
  clip-path: path('M 0 8 A 4 4 0 0 0 2.82842712474619 6.82842712474619 L 6.585786437626905 3.0710678118654755 A 2 2 0 0 1 9.414213562373096 3.0710678118654755 L 13.17157287525381 6.82842712474619 A 4 4 0 0 0 16 8 Z');
}
body .ant-tooltip > .ant-tooltip-arrow {
  width: 16px;
  height: 8px;
  clip-path: path('M 0 8 A 4 4 0 0 0 2.82842712474619 6.82842712474619 L 6.585786437626905 3.0710678118654755 A 2 2 0 0 1 9.414213562373096 3.0710678118654755 L 13.17157287525381 6.82842712474619 A 4 4 0 0 0 16 8 Z');
}
body canvas {
  display: block;
}
body ul,
body ol {
  padding: 0;
  list-style: none;
}
body .markdown-content ul {
  padding-left: 20px;
  list-style-type: disc;
}
body .markdown-content ol {
  padding-left: 20px;
  list-style-type: decimal;
}
body .markdown-content blockquote {
  padding-left: 8px;
  border-left: 4px solid #ccc;
}
body .df-center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
body .df-notification.ant-notification-notice {
  display: flex;
  align-items: center;
  height: auto;
  max-height: 80px;
  padding: 10px !important;
  color: white;
  font-size: 14px;
  background: #100f1e !important;
}
body .df-notification.ant-notification-notice .ant-notification-notice-message {
  margin: 0;
  padding: 0;
  color: white !important;
  font-size: 14px;
}
body .df-notification.ant-notification-notice .ant-notification-notice-description {
  color: white !important;
}
body .df-notification.ant-notification-notice .ant-notification-notice-close {
  top: 10px;
  right: 10px;
  color: white !important;
}
body .divider {
  margin: 0 5px;
}
body .df-menu-container {
  min-width: 160px;
  padding: 5px 0;
  background: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
}
body .df-menu-item-container {
  cursor: pointer;
  transition: all 0.3s;
}
body .df-menu-item-container .df-menu-item {
  display: inline-block;
  width: 100%;
  padding: 5px 12px;
}
body .df-menu-item-container:hover {
  background: #bbaeb3;
}
body .more-dots-icon {
  display: flex;
  justify-content: center;
  width: 20px;
  padding: 4px;
  border-radius: 2px;
  cursor: pointer;
}
body .more-dots-icon:hover {
  background: #bbaeb3;
}
body .line-down-down {
  position: relative;
  width: 15px !important;
}
body .line-down-down::before {
  position: absolute;
  top: 2px;
  left: 7px;
  width: 1px;
  height: calc(100% - 4px);
  background-color: #ADB5BD;
  border-radius: 1px;
  content: '';
}
body .line-down-arrow {
  position: relative;
  width: 15px !important;
}
body .line-down-arrow::before {
  position: absolute;
  top: 2px;
  left: 7px;
  width: 1px;
  height: calc(100% - 4px);
  background-color: #ADB5BD;
  border-radius: 1px;
  content: '';
}
body .line-down-arrow::after {
  position: absolute;
  bottom: 2px;
  left: calc(50% - 3px);
  width: 5px;
  height: 5px;
  border-right: 1px solid #ADB5BD;
  border-bottom: 1px solid #ADB5BD;
  transform: rotateZ(45deg);
  transform-origin: 50% 50%;
  content: '';
}
body .df-link {
  color: #0045F7;
  cursor: pointer;
}
body .df-full-height-tabs {
  height: 100%;
}
body .df-full-height-tabs :global .ant-tabs-content {
  flex: 1;
  height: 100%;
}
body .df-full-height-tabs :global .ant-tabs-content-holder {
  display: flex;
  flex-direction: column;
}
body .df-tile-overlaid {
  height: calc(218px - 60px);
  overflow: hidden;
}
body .df-tile-overlaid .react-resizable-handle {
  z-index: 9;
}
body .df-tile-overlaid .react-resizable-handle::after {
  border-color: rgba(255, 255, 255, 0.6) !important;
}
body .df-tile-overlaid .df-tile-container {
  height: calc(218px - 60px);
  margin: 0;
}
body .df-tile-overlaid .df-tile-container .df-tile-content {
  height: calc(218px - 60px);
}
body .df-tile-overlaid .df-tile-container .df-tile-content .df-tile-body {
  height: calc(218px - 60px);
}
body .df-tile-overlaid .df-tile-container .df-tile-content .df-tile-footer {
  position: absolute;
  bottom: 0;
  z-index: 5;
  width: 100%;
  color: #ADB5BD;
  background-color: rgba(0, 0, 0, 0.3);
}
body .df-tile-container {
  position: relative;
  display: inline-block;
  margin: 0 16px 16px 0;
  color: #212529;
}
body .df-tile-container[active-channel='true'] .df-tile-content .df-tile-body,
body .df-tile-container[checked-channel='true'] .df-tile-content .df-tile-body {
  border: 1px solid #0045F7;
  border-bottom: 1px solid #E9ECEF;
}
body .df-tile-container[active-channel='true'] .df-tile-content .df-tile-footer,
body .df-tile-container[checked-channel='true'] .df-tile-content .df-tile-footer {
  border: 1px solid #0045F7;
  border-top: none;
}
body .df-tile-container .df-tile-content {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 282px;
  overflow: hidden;
  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0px 2px 16px -6px #090a0b1f;
}
body .df-tile-container .df-tile-content .df-tile-body {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(218px - 58px);
  overflow: hidden;
  border-radius: 8px 8px 0 0;
  cursor: pointer;
}
body .df-tile-container .df-tile-content .df-tile-body .df-tile-image-stream {
  position: absolute;
  z-index: 5;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 2px;
  pointer-events: none;
}
body .df-tile-container .df-tile-content .df-tile-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60px;
  border: 1px solid #E9ECEF;
  border-top: none;
  border-radius: 0 0 8px 8px;
}
body .view-channel-tile-container {
  overflow: hidden;
  background-color: black;
}
body .view-channel-tile-container :global .react-resizable-handle {
  z-index: 9;
}
body .view-channel-tile-container :global .react-resizable-handle::after {
  border-color: rgba(255, 255, 255, 0.6) !important;
}
body .view-channel-tile-container :global .df-tile-container {
  width: 100%;
  height: 100%;
  margin: 0;
  pointer-events: none;
}
body .view-channel-tile-container :global .df-tile-container .df-tile-content {
  width: 100%;
  height: 100%;
  background-color: initial;
}
body .view-channel-tile-container :global .df-tile-container .df-tile-content a {
  height: 100%;
}
body .view-channel-tile-container :global .df-tile-container .df-tile-content .df-tile-body {
  width: 100%;
  height: 100%;
  border-color: black;
}
body .view-channel-tile-container :global .df-tile-container .df-tile-content .df-tile-footer {
  position: absolute;
  bottom: 0;
  z-index: 5;
  width: 100%;
  color: #ADB5BD;
  background-color: rgba(0, 0, 0, 0.3);
  border-color: black;
  pointer-events: none;
}
body .df-tile-badge {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 2;
  padding: 1px 4px;
  color: white;
  font-weight: 600;
  font-size: 10px;
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  background-color: red;
  border-radius: 4px;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  cursor: pointer;
}
body .df-tile-container-disabled {
  position: relative;
  border-radius: 8px;
}
body .df-tile-container-disabled::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(33, 33, 33, 0.05);
  border-radius: 8px;
  content: '';
  pointer-events: none;
}
body .df-icontile-container {
  display: inline-block;
  width: 282px;
  margin: 0 16px 16px 0;
  overflow: hidden;
  background: #FFFFFF;
  border: 1px solid #E9ECEF;
  border-radius: 8px;
  box-shadow: 0px 2px 16px -6px #090a0b1f;
  cursor: pointer;
}
body .df-icontile-container .df-icontile-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 124px;
}
body .df-icontile-container .df-icontile-title-icon {
  padding-bottom: 15px;
  color: #212529;
  font-size: 30px;
}
body .df-icontile-container .df-icontile-title-text {
  width: 100%;
  padding: 0 10px;
  overflow: hidden;
  color: #212529;
  font-size: 14px;
  white-space: nowrap;
  text-align: center;
  text-overflow: ellipsis;
}
body .df-setting-component-ctn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding-right: 16px;
  padding-bottom: 16px;
}
body .df-setting-component-ctn .df-setting-component-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: calc(100% - 80px);
  overflow-x: hidden;
  overflow-y: auto;
}
body .df-setting-component-ctn .df-setting-component-list .df-setting-component {
  width: 100%;
}
body .df-setting-component-ctn .df-setting-component-list .df-setting-component .df-component-title {
  width: 100%;
  height: 20px;
  margin-bottom: 4px;
  color: #000;
  font-weight: 500;
  font-size: 14px;
  font-family: Barlow, sans-serif;
  font-style: normal;
  line-height: 20px;
  letter-spacing: -11e;
}
body .df-setting-component-ctn .df-setting-component-list .df-setting-component .df-component-subtitle {
  width: 100%;
  margin-bottom: 8px;
  color: #343A40;
  font-weight: normal;
  font-size: 14px;
  font-family: Barlow, sans-serif;
  font-style: normal;
  line-height: 20px;
  letter-spacing: -11e;
}
body .df-setting-component-ctn .df-setting-component-list .df-setting-component .df-component-select {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-width: 210px;
  margin-bottom: 12px;
}
body .df-setting-component-ctn .df-setting-component-list .df-setting-component .df-component-select .df-select-option-ctn {
  position: relative;
}
body .df-setting-component-ctn .df-setting-component-list .df-setting-component .df-component-radio {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 12px;
}
body .df-setting-component-ctn .df-setting-component-list .df-setting-component .df-component-radio:last-child {
  margin-bottom: 6px;
}
body .df-setting-component-ctn .df-setting-component-list .df-setting-component .df-component-radio .df-component-radio-children {
  width: 100%;
  padding-bottom: 16px;
  padding-left: 24px;
}
body .df-setting-component-ctn .df-setting-component-list .df-setting-component .df-components-action-btns {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  margin-top: 16px;
}
body .df-setting-component-ctn .df-setting-action-btns {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  height: 80px;
}
body .df-setting-component-ctn .df-action-btn {
  width: fit-content;
}
body .no-horizontal-padding-collapse :global .ant-collapse-header {
  padding: 10px 0px !important;
}
body :global .ant-modal-content {
  overflow: hidden;
  background: #FFFFFF;
  border: 1px solid #E9ECEF;
  border-radius: 8px;
  box-shadow: 4px 4px 8px -4px rgba(0, 0, 0, 0.08), 4px 4px 16px rgba(9, 10, 11, 0.08);
}
body :global .ant-modal-body::-webkit-scrollbar {
  width: 5px;
}
body :global .ant-modal-body::-webkit-scrollbar-track {
  background: #FFFFFF;
}
body :global .ant-modal-body::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 5px;
}
body :global .ant-modal-body::-webkit-scrollbar-thumb:hover {
  background: #888;
}
body :global .ant-tabs-nav::-webkit-scrollbar {
  width: 5px;
}
body :global .ant-tabs-nav::-webkit-scrollbar-track {
  background: #FFFFFF;
}
body :global .ant-tabs-nav::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 5px;
}
body :global .ant-tabs-nav::-webkit-scrollbar-thumb:hover {
  background: #888;
}
body .scroll-bar-slim-style::-webkit-scrollbar {
  width: 5px;
}
body .scroll-bar-slim-style::-webkit-scrollbar-track {
  background: #FFFFFF;
}
body .scroll-bar-slim-style::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 5px;
}
body .scroll-bar-slim-style::-webkit-scrollbar-thumb:hover {
  background: #888;
}
body .scroll-bar-slim-style2::-webkit-scrollbar {
  width: 5px;
}
body .scroll-bar-slim-style2::-webkit-scrollbar-track {
  background: #FFFFFF;
}
body .scroll-bar-slim-style2::-webkit-scrollbar-thumb {
  background: #DEE2E6;
  border-radius: 5px;
}
body .scroll-bar-slim-style2::-webkit-scrollbar-thumb:hover {
  background: #CED4DA;
}
body .disabled-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(33, 33, 33, 0.05);
  cursor: not-allowed;
}
body .horizontal-divider {
  margin: 0 16px 16px 0;
  border-top: 1px solid #E9ECEF;
}
html:root {
  --padding-lg: 24px;
  --padding-md: 16px;
  --padding-sm: 12px;
  --padding-xs: 8px;
  --padding-xss: 4px;
}
body .df-ctn {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: hidden;
}
body .df-flex-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
body .df-flex-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
body .df-x-scroll-ctn {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: auto;
  overflow-y: hidden;
}
body .df-y-scroll-ctn {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
}
body .df-scroll-ctn {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: auto;
  overflow-y: auto;
}
body .df-warn-text {
  color: #FF6D00;
}
body .df-error-text {
  color: #B80000;
}
body .no-padding {
  padding: 0px !important;
}
body .df-description {
  color: #6C757D;
  font-size: 14px;
}
